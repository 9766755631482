import "./global.css";

import { useScrollToTop } from "./hooks/use-scroll-to-top";
import { ThemeProvider as CustomThemesOnly } from "@emotion/react";

import Router from "./routes/sections";
import ThemeProvider from "./theme";
import themeButtons from "./theme/custom themes/customTheme";

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <ThemeProvider>
      <CustomThemesOnly theme={themeButtons}>
        <Router />
      </CustomThemesOnly>
    </ThemeProvider>
  );
}
