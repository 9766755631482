// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/simplebar-react/dist/simplebar.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* scrollbar */
.MuiDataGrid-columnHeaders   {
  background-color: #c8d9ed !important;
color:#4663ac;
font-size: larger;

  }

  .MuiDataGrid-columnHeader {
    background-color: #4663ac !important; /* Header cell background color */
    color: white !important; /* Header cell text color */
}
  .MuiDataGrid-cell {
    background-color: #c8d9ed; /* or your preferred color */
    color:black;
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA,cAAc;AAEd;EACE,oCAAoC;AACtC,aAAa;AACb,iBAAiB;;EAEf;;EAEA;IACE,oCAAoC,EAAE,iCAAiC;IACvE,uBAAuB,EAAE,2BAA2B;AACxD;EACE;IACE,yBAAyB,EAAE,4BAA4B;IACvD,WAAW;AACf","sourcesContent":["/* scrollbar */\n@import 'simplebar-react/dist/simplebar.min.css';\n.MuiDataGrid-columnHeaders   {\n  background-color: #c8d9ed !important;\ncolor:#4663ac;\nfont-size: larger;\n\n  }\n\n  .MuiDataGrid-columnHeader {\n    background-color: #4663ac !important; /* Header cell background color */\n    color: white !important; /* Header cell text color */\n}\n  .MuiDataGrid-cell {\n    background-color: #c8d9ed; /* or your preferred color */\n    color:black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
