import { createTheme } from "@mui/material/styles";

const themeButtons = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "add" },
          style: {
            backgroundColor: "#4663AC",
            color: "#fff",
            textTransform: "none",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#388e3c",
            },
          },
        },
        {
          props: { variant: "delete" },
          style: {
            backgroundColor: "#f44336",
            color: "#fff",
            textTransform: "none",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#d32f2f",
            },
          },
        },
      ],
    },
  },
});

export default themeButtons;
